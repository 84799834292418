import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ContentView from "./components/ContentView/ContentView.jsx";
import Header from './components/Header/Header.jsx'
import Admin from "./pages/Admin/Admin.jsx";
import PageNotFound from "./pages/PageNotFound/PageNotFound.jsx";
import Private from "./Routes/Private.jsx";
import constants from "./Utils/Constants.js";
import LaborOrders from "./pages/LaborOrders/LaborOrders"
import CreateLaborOrder from "./pages/LaborOrders/CreateLaborOrder/CreateLaborOrder";
import ViewLaborOrder from "./pages/LaborOrders/ViewLaborOrder/ViewLaborOrder";
import CreateRequisition from "./pages/Requisitions/CreateRequisition/CreateRequisition";
import ViewRequisition from "./pages/Requisitions/ViewRequisition/ViewRequisition";
import Requisitions from "./pages/Requisitions/Requisitions";
import Reports from "./pages/Reports/Reports";
import CandidateManagement from "./pages/CandidateManagement/CandidateManagement";
import { useGetAppConfig } from "./ReactQuery/hooks/useGetAppConfig.js";
import {FEATURE_FLAGS_LOCALSTORAGE_KEYS} from "./Utils/Constants.js";

function App() {
  const [, setUserDetails] = useState({});

  useGetAppConfig({
    onSuccess: (res) => {
      localStorage.setItem(FEATURE_FLAGS_LOCALSTORAGE_KEYS.EDM_INTEGRATION, res?.data?.FEATURE_FLAG?.EDM_INTEGRATION === 'TRUE');
    }
  });
  
  useEffect(() => {
    const sessionUser = sessionStorage.getItem('session_user');
    if (sessionUser) {
      setUserDetails(JSON.parse(sessionUser));
      reportUsername(JSON.parse(sessionUser).name);
    }
  }, []);
  return (
    <>
      <Router>
        <div className="App">
         {<> <Header />
          <ContentView />
          </>}
        </div>
        <Switch>
          <Private path="/" exact component={LaborOrders} />
          <Private path="/laborOrders" exact component={LaborOrders} />
          <Private path={`/${constants.LABOR_ORDERS.ROUTE.MAIN}`} exact component={LaborOrders} />
          <Private path={`/${constants.LABOR_ORDERS.ROUTE.VIEW}/:id`} exact component={ViewLaborOrder} />
          <Private path={`/${constants.LABOR_ORDERS.ROUTE.CREATE}`} exact component={CreateLaborOrder} />
          <Private path={`/${constants.LABOR_ORDERS.ROUTE.CREATE_RELATED}/:relatedName/:relatedID`} exact component={CreateLaborOrder} />
          <Private path={`/${constants.LABOR_ORDERS.ROUTE.EDIT}/:id`} exact component={CreateLaborOrder} />

          <Private path={`/${constants.REQUISITIONS.ROUTE.MAIN}`} exact component={Requisitions} />
          <Private path={`/${constants.REQUISITIONS.ROUTE.MAIN}/${constants.REQUISITIONS.ROUTE_RELATED}/:LOfilter`} exact component={Requisitions} />
          <Private path={`/${constants.REQUISITIONS.ROUTE.VIEW}/:LOid&:id`} exact component={ViewRequisition} />
          <Private path={`/${constants.REQUISITIONS.ROUTE.CREATE}`} exact component={CreateRequisition} />
          <Private path={`/${constants.REQUISITIONS.ROUTE.CREATE}/${constants.REQUISITIONS.ROUTE_RELATED}/:LOid`} exact component={CreateRequisition} />
          <Private path={`/${constants.REQUISITIONS.ROUTE.CREATE_RELATED}/:relatedName/:relatedID`} exact component={CreateRequisition} />
          <Private path={`/${constants.REQUISITIONS.ROUTE.EDIT}/:LOid&:id`} exact component={CreateRequisition} />
          <Private path={`/${constants.REPORTS.ROUTE.MAIN}`} exact component={Reports} />
          <Private path={`/${constants.CANDIDATE_MANAGEMENT.ROUTE.MAIN}`} exact component={CandidateManagement} />

          <Private path="/admin" exact component={Admin} />
          <Route path="*" exact component={PageNotFound} />
        </Switch>
      </Router>
    </>
  );
}

function reportUsername(username) {
  if(username !== '') {
    const authenticatedUserEvent = new CustomEvent('cathode-customerId', {
      detail: {
        customerId: username
      }
    });
    document.dispatchEvent(authenticatedUserEvent);
  }
};

export default App;
